import React from 'react';
import { BiWinkSmile } from 'react-icons/bi'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import { GoGlobe } from 'react-icons/go'
import featuresImg from '../../img/features.png';
import { FeaturesIcon } from './features.icon';
import {
    FeaturesCtn,
    FeaturesTitleCtn,
    FeaturesTitle,
    FeaturesSubTitle,
    FeaturesIconCtn,
    FeaturesFlex,
    FeaturesImgCtn
} from './features.elements';


export class Features extends React.Component {
    render() {
        return(

            <FeaturesCtn id="Caracteristici">

                <FeaturesTitleCtn>

                    <FeaturesTitle>DE CE DRAGOSERV</FeaturesTitle>

                    <FeaturesSubTitle>Caracteristicile Noastre</FeaturesSubTitle>

                    <hr/>

                </FeaturesTitleCtn>

                <FeaturesFlex>

                    <FeaturesIconCtn>
                    
                        <FeaturesIcon
                        icon={ <BiWinkSmile className="icon"/> }
                        title="100% CLIENȚI MULȚUMIȚI"
                        text="Clientii nostri sunt printre cei mai norocosi oameni, isi primesc marfă in siguranță si la timp."
                        />

                        <FeaturesIcon
                        icon={ <AiOutlineSafetyCertificate className="icon"/> }
                        title="SERVICII DE CALITATE"
                        text="Vă oferim servicii de calitate atât național cât si internațional."
                        />

                        <FeaturesIcon
                        icon={ <GoGlobe className="icon"/> }
                        title="INTERN SI EXTERN"
                        text="Dragoserv oferă servicii atât național cât si internațional."
                        />

                    </FeaturesIconCtn>

                    <FeaturesImgCtn>

                        <img src={featuresImg} />

                    </FeaturesImgCtn>

                </FeaturesFlex>

            </FeaturesCtn>

        )
    }
}

export default Features