import styled from 'styled-components';

export const AboutCtn = styled.div`
    width: 80%;
    margin: 2em auto;

    @media screen and (min-width: 1920px) {
        width: 60%;
    }
`;

export const AboutFlex = styled.div`
    @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
    }
`;

export const AboutImg = styled.div`
    display: none;
    img {
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        display: block;
    }

    @media screen and (min-width: 1280px) {
        img {
            width: 90%;
        }
    }
`;

export const AboutImgMobile = styled.div`
    img {
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

export const AboutText = styled.div`
    @media screen and (min-width: 1024px) {
        padding: 20px;
    }
`;

export const AboutTitle = styled.h2`
    font-size: 30px;
`;

export const AboutSubTitle = styled.h3`
    font-size: 18px;
    font-weight: 600;
`;

export const AboutParagraph = styled.p`
    font-size: 16px;
    color: #474770;
`;
