import React from 'react';
import { NavStickyScroll } from '../components/navbar/index';
import img01 from '../img/services1.jpg';
import img02 from '../img/services2.jpg';
import img03 from '../img/services3.jpg';
import img04 from '../img/services4.jpg';
import img05 from '../img/services5.jpg';
import img06 from '../img/services6.jpg';
import {
    ServicesDiv,
    ServicesTitle,
    ServicesCtn,
    SerivicesCardText,
    ServicesCardCtn,
    ServicesCardTitle,
    ServicesCardContent,
    ServicesCardHover,
    ServicesHR,
    ServicesText
} from './page.elements';

window.addEventListener('scroll', NavStickyScroll);


class ServiceCard extends React.Component {
    
    render() {
        
        // function ServicesHoverFn() {
        //     const servicesHover = document.querySelector('.servicesHover');
        //     const servicesCard = document.querySelector('.servicesCard');
        //     servicesCard.style.display = 'none';
        //     servicesHover.style.visibility = 'visible';
        //     servicesHover.classList.add('active');
        // }

        return(
            <ServicesCardCtn id="servicesPage">

                <img src={this.props.src} alt={this.props.alt}/>

                <ServicesCardContent className="servicesCard">
                    <ServicesCardTitle>{this.props.title}</ServicesCardTitle>
                    <ServicesCardHover className="servicesHover">
                        <SerivicesCardText>{this.props.text}</SerivicesCardText>
                    </ServicesCardHover>
                </ServicesCardContent>

            </ServicesCardCtn>
        )
    }
}

export class servicesPage extends React.Component {
    render() {
        return(
            <ServicesDiv>
                <ServicesTitle>SERVICII</ServicesTitle>
                <ServicesHR />
                <ServicesText>
                    Dragoserv este o firma ce se ocupa cu transportul de marfa generala cu ajutorul camioanelor, atat intern cat si extern.
                    Noi suntem fericiti doar daca si clientii nostri sunt multumiti de calitatea serviciilor.
                    Acest lucru este usor de indeplinit pentru ca la Dragoserv principalul obiectiv este de a oferii servicii de calitate.
                </ServicesText>

                <ServicesCtn>
                    <ServiceCard
                        src={img01} 
                        title="Îngrășăminte"
                        text="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                        alt="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                    />
                    <ServiceCard
                        src={img02}
                        title="Hrană Animală"
                        text="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                        alt="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                    />
                    <ServiceCard 
                        src={img03}
                        title="Cereale"
                        text="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                        alt="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                    />
                    <ServiceCard 
                        src={img04}
                        title="Produse Metalice"
                        text="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                        alt="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                    />
                    <ServiceCard 
                        src={img05}
                        title="Baloti"
                        text="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                        alt="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                    />
                    <ServiceCard 
                        src={img06}
                        title="Marfa Paletizata"
                        text="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                        alt="Floarea soarelui, grau, orz, porumb, rapita, soia etc."
                    />
                </ServicesCtn>
            </ServicesDiv>
        )
    }
}

export default servicesPage