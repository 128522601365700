import styled from 'styled-components';
import BannerBG  from '../../img/banner.png';

export const BlogPostDiv = styled.div``  

export const BlogPostBanner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${BannerBG});
    background-size: cover;
    margin-top: 3.5em;
    width: 100%;
    height: 300px;

    @media screen and (max-width: 1024px) {
        height: 200px;
        text-align: center;
    }
`

export const BlogPostTitle = styled.h1`
    color: #fff;
    font-size: 60px;
    width: 60%;
    margin: 0 auto;
    padding: 20px;

    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
`

export const BlogPostCtn = styled.div`
    width: 60%;
    margin: 2em auto;
`

export const BlogPostFlex = styled.div`
    display: flex;
    justify-content: space-between;
`

export const BlogPostContent = styled.div`
    width: 70%;

    .blogImg {
        margin-top: 1em;
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`

export const BlogPostSubTitle = styled.h2`
    font-size: 35px;

    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
`

export const BlogPostIcons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;

    @media screen and (max-width: 1024px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
`

export const BlogPostIconsP = styled.p`
    color: #A2A2A2;
    margin-left: 10px;
`

export const BlogPostCalendar = styled.div`
    display: flex;
    align-items: center;

    .blogIcon {
        color: #A2A2A2;
    }
`

export const BlogPostUser = styled.div`
    display: flex;
    align-items: center;

    .blogIcon {
        color: #A2A2A2;
    }
`

export const BlogPostSubject = styled.div`
    display: flex;
    align-items: center;

    .blogIcon {
        color: #A2A2A2;
    }
`

export const BlogPostText = styled.p`
    font-size: 20px;
    color: #474747;
`

export const BlogRecentPostCtn = styled.div`
    width: 25%;

    .recentPostImg {
        cursor: pointer;
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
`

export const RecentPost = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2em;
`

export const RecentPostLink = styled.a`
    color: #F5AF0C;
    cursor: pointer;
    font-size: 22px;
    font-weight: 200;
    margin-top: 0.5em;

    &:hover {
        color: black;
        transition: 0.2s ease;
    }
`

export const RecentPostTitle = styled.h2`
    font-size: 35px;
`
