import React from 'react';
import img from '../../img/about.jpg'
import {
    AboutCtn,
    AboutFlex,
    AboutImg,
    AboutText,
    AboutTitle,
    AboutSubTitle,
    AboutParagraph,
    AboutImgMobile
} from './about.elements';

export class About extends React.Component {
    render() {
        return(
            <AboutCtn id="DespreNoi">

                <AboutFlex>

                    <AboutImg>
                        <img src={img} alt="Dragoserv About" />
                    </AboutImg>

                    <AboutText>

                        <AboutTitle>Despre Noi - Transport de marfă cu camioane</AboutTitle>

                        <AboutSubTitle>Societatea noastră a fost înființată in anul 1994 , ca o afacere de familie.</AboutSubTitle>

                        <AboutParagraph>
                            Datorită profesionalismului si seriozității de care am dat dovadă am reușit să ne formăm clienți fideli cu care lucrăm si astăzi.
                        </AboutParagraph>

                        <AboutParagraph>
                            Dragoserv deține un număr de 10 capete tractor , MAN, Semiremorci basculabile, Schmitz
                            Noi suntem fericiți doar dacă si clienții nostri sunt mulțumiți de calitatea serviciilor.
                            Acest lucru este ușor de îndeplinit pentru că la Dragoserv principalul obiectiv este de a oferii servicii de calitate.
                        </AboutParagraph>

                    </AboutText>

                    <AboutImgMobile>
                        <img src={img} alt="Dragoserv About" />
                    </AboutImgMobile>
                    
                </AboutFlex>

            </AboutCtn>
        )
    }
}

export default About