import Navbar from './components/navbar';
import Home from './pages/home';
import ServicesPage from './pages/servicesPage';
import Blog from './pages/blog';
import Blog01 from './pages/blogPost01';
import Blog02 from './pages/blogPost02';
import Blog03 from './pages/blogPost03';
import Footer from './components/footer';

import { FaArrowUp } from 'react-icons/fa';
import { ScrollTopDiv, ScrollTopDivCtn } from './components/navbar/navbar.elements'
import { Link as LinkS} from 'react-scroll';
import { BrowserRouter as Router , Switch, Route} from 'react-router-dom';

function scrollTop() {
  const scrollTopDiv = document.querySelector('.scrollTopDiv');

  if(window.pageYOffset <= 840) {
    scrollTopDiv.style.display = 'none';
    scrollTopDiv.style.transition = '0.3s ease';
  } else if(window.pageYOffset > 840) {
    scrollTopDiv.style.display = 'block';
  }
}

window.addEventListener('scroll', scrollTop);

function App() {
  return (
    <div className="App">
      <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/servicii" component={ServicesPage}/>
        <Route path="/blog" component={Blog}/>
        <Route path="/pozitionare-exacta-a-vehiculelor-autonome" component={Blog01}/>
        <Route path="/transportul-auto-in-situatii-de-criza" component={Blog02}/>
        <Route path="/cat-consuma-un-camion-5-pasi-simpli-pentru-reducerea-consumului-iarna" component={Blog03}/>
      </Switch>
      <Footer />
      </Router>

      <ScrollTopDiv className="scrollTopDiv">

        <ScrollTopDivCtn>

        <LinkS
          activeClass="active"
          to="App"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          <FaArrowUp className="arrowUp"/>
        </LinkS>

        </ScrollTopDivCtn>
        
      </ScrollTopDiv>

    </div>
  );
}

export default App;
