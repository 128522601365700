import styled from 'styled-components';


export const ServicesBG = styled.div`
    background-color: #140B05;
    margin-top: 2em;
`;

export const ServicesContainer = styled.div`
    padding: 20px 0px;
    padding-bottom: 60px;
    width: 80%;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (min-width: 1920px) {
        width: 60%;
    }
`;

export const ServicesCardContent = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 0px 0px 20px 0px;

    @media screen and (min-width: 1024px) {
        width: 100%;
        padding: 10px;
    }

    @media screen and (min-width: 1280px) {
        padding: 0px 20px 20px 20px;
    }
`;

export const ServicesCardCtn = styled.div`
    background-color: #fff;
    margin-top: 2em;

    img {
        width: 100%;
    }
`;

export const ServicesCardTitle = styled.h2`
    font-weight: 500;

    @media screen and (min-width: 1024px) {
        font-size: 18px;
    }
`;

export const SericesCardText = styled.p`

    @media screen and (min-width: 1024px) {
        font-size: 15px;
        width: 90%;
    }
`;

export const ServicesCardBtn = styled.button`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 700;
    border-radius: 4px;
    cursor: pointer;
    width: 120px;
    height: 40px;
    background-color: #F1A70C;
    border: none;

    &:hover {
        background-color: #140B05;
        transition: 0.3s ease-in;
        border: none;
    }

    a {
        color: #fff;
        text-decoration: none;
    }
`;

export const ServicesTitle = styled.h1`
    color: #fff;
`;

export const ServicesParagraph = styled.p`
    color: #ccc;
`;

export const ServicesBtn = styled.button`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
    font-weight: 700;
    width: 140px;
    height: 40px;

    &:hover {
        background: #F1A70C;
        border: none;
        transition: 0.3s ease-in;
    }

    a {
        text-decoration: none;
        color: #fff;
    }
`;

export const ServicesCol1 = styled.div`

    @media screen and (min-width: 1024px) {
        width: 200px;
    }

    @media screen and (min-width: 1280px) {
        width: 200px;
    }
`

export const ServicesCol2 = styled.div`
    @media screen and (min-width: 1024px) {
        width: 200px;
    }

    @media screen and (min-width: 1280px) {
        width: 250px;
    }

    @media screen and (min-width: 1600px) {
        width: 340px;
    }

    @media screen and (min-width: 1920px) {
        width: 300px;
    }
`;

export const ServicesCol3 = styled.div`
    @media screen and (min-width: 1024px) {
        width: 200px;
    }

    @media screen and (min-width: 1280px) {
        width: 250px;
    }

    @media screen and (min-width: 1600px) {
        width: 340px;
    }

    @media screen and (min-width: 1920px) {
        width: 300px;
    }
`;

export const ServicesCol4 = styled.div`
    @media screen and (min-width: 1024px) {
        width: 200px;
    }

    @media screen and (min-width: 1280px) {
        width: 250px;
    }

    @media screen and (min-width: 1600px) {
        width: 340px;
    }

    @media screen and (min-width: 1920px) {
        width: 300px;
    }
`;