import React from 'react'
import {FaQuoteLeft} from 'react-icons/fa';
import {
    ClientsCardCtn,
    ClientsCardText,
    ClientsCardName,
    ClientsCardRole
} from './clients.elements';

export class ClientsCard extends React.Component {

    render() {
        return (
            
            <ClientsCardCtn>

                <FaQuoteLeft className="quoteIcon"/>

                <ClientsCardText>{this.props.text}</ClientsCardText>
                
                <ClientsCardName>{this.props.name}</ClientsCardName>

                <ClientsCardRole>{this.props.role}</ClientsCardRole>

            </ClientsCardCtn>

        )
    }
}

export default ClientsCard
