import React from 'react';
import { Link } from 'react-scroll';
import {
    SolutionBG,
    SolutionBtn,
    SolutionCtn,
    SolutionText,
    SolutionTitle
} from './solution.elements';

export class Solution extends React.Component {
    render() {
        return(
            <SolutionBG>

                <SolutionCtn>

                    <SolutionTitle>Lasă-ne să îți oferim cea mai bună soluție pentru nevoile tale</SolutionTitle>
                    <SolutionText>Dacă ai nevoie de servicii de transport marfă și consideri că noi suntem cea mai bună alegere, nu ezita să ne contactezi.</SolutionText>
                    <SolutionBtn>

                        <Link
                            activeClass="active"
                            to="Contact"
                            spy={true}
                            smooth={true}
                            offset={-200}
                            duration={500}
                        >
                            <a href="">CONTACT</a>
                        </Link>

                        
                    </SolutionBtn>

                </SolutionCtn>

            </SolutionBG>
        )
    }
}

export default Solution