import React from 'react';
import { 
    FeaturesIconFlex,
    FeaturesIconFlexCtn,
    FeaturesIconFlexText,
    FeaturesIconTitle,
    FeaturesIconParagraph
} from './features.elements';

export class FeaturesIcon extends React.Component {
    render() {
        return(
            <FeaturesIconFlex>
                
                <FeaturesIconFlexCtn>{this.props.icon}</FeaturesIconFlexCtn>

                <FeaturesIconFlexText>

                    <FeaturesIconTitle>{this.props.title}</FeaturesIconTitle>
                    
                    <FeaturesIconParagraph>{this.props.text}</FeaturesIconParagraph>
                
                </FeaturesIconFlexText>

            </FeaturesIconFlex>
        )
    }
}

export default FeaturesIcon