import About from '../components/about';
import Banner from '../components/banner';
import Clients from '../components/clients';
import Features from '../components/features';
import Maps from '../components/maps';
import News from '../components/news';
import Number from '../components/number';
import Services from '../components/services';
import Solution from '../components/solution';

function Home() {
    return(
        <div>
            <Banner />
            <About />
            <Services />
            <Features />
            <Number />
            <Clients />
            <Solution />
            <News />
            <Maps />
        </div>
    );
}

export default Home