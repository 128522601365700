import styled from 'styled-components';

export const ClientsBG = styled.div`
    background-color: #F6F5F3;
    padding: 20px;
    padding-bottom: 60px;
`

export const ClientsTitle = styled.h1`
    font-size: 25px;
    text-align: center;
    margin: 2em 0em;
`

export const ClientsCtn = styled.div`
    width: 80%;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (min-width: 1920px) {
        width: 60%;
    }
`

export const ClientsCardCtn = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;

    .quoteIcon {
        color: #F1A70C;
        font-size: 40px;
    }

    @media screen and (min-width: 1024px) {
        width: 340px;
    }

    @media screen and (min-width: 1280px) {
        width: 450px;
    }
`

export const ClientsCardText = styled.p``

export const ClientsCardName = styled.h3`
    font-weight: 500;
    position: relative;
    top: 15px;
`

export const ClientsCardRole = styled.h4`
    color: #F1A70C;
    font-weight: lighter;
`

export const ClientsCol = styled.div`
    
    @media screen and (max-width: 768px) {
        margin-top: 2em;
    }
`



