import styled from 'styled-components';
import BgImg from '../../img/banner.png';

export const BannerBackgroundImage = styled.div`
    background: url(${BgImg});
    background-size: cover;
    height: 100vh;
    padding: 20px;

    @media screen and (min-width: 1024px) {
        height: 100vh;
    }
`;

export const BannerContainer = styled.div`
    height: 100%;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: 1920px) {
        width: 60%;
    }
`;

export const BannerTitle = styled.h1`
    color: #fff;

    @media screen and (max-width: 280px) {
        font-size: 20px;
    }

    @media screen and (min-width: 320px) and (max-width: 375px){
        font-size: 25px;
    }

    @media screen and (min-width: 768px) {
        font-size: 40px;
    }
`;

export const BannerText = styled.p`
    color: #E8E8E8;

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }

    @media screen and (min-width: 1280px) {
        width: 650px;
    }
`;

export const BannerBtnCtn = styled.div`
    display: flex;
    margin-top: 2em;
`;

export const BannerBtnActive = styled.button`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 700;
    background-color: #F1A70C;
    width: 160px;
    height: 50px;
    border: none;
    border-radius: 4px;

    a {
        cursor: pointer;
        color: #fff;
        text-decoration: none;
    }

    &:hover {
        background-color: #ccc;
        transition: 0.3s ease-in;
    }

    @media screen and (max-width: 280px) {
        font-size: 12px;
        width: 160px;
        height: 40px;
    }

    @media screen and (max-width: 320px) {
        font-size: 12px;
        width: 140px;
        height: 40px;
    }

    @media screen and (min-width: 360px) and (max-width: 375px) {
        font-size: 12px;
        width: 120px;
        height: 40px;
    }
`;

export const BannerBtn = styled.button`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 700;
    background: transparent;
    width: 160px;
    height: 50px;
    border: 2px solid #ccc;
    border-radius: 4px;
    margin-left: 1em;

    a {
        color: #fff;
        text-decoration: none;
    }

    &:hover {
        background-color: #140B05;
        color: #F1A70C;
        border: 2px solid #140B05;
        transition: 0.3s ease-in;
    }

    @media screen and (max-width: 280px) {
        font-size: 12px;
        width: 160px;
        height: 40px;
    }

    @media screen and (max-width: 320px) {
        font-size: 12px;
        width: 140px;
        height: 40px;
    }

    @media screen and (min-width: 360px) and (max-width: 375px) {
        font-size: 12px;
        width: 120px;
        height: 40px;
    }
`;