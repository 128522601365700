import React from 'react';
import { FaRegCalendarAlt, FaUserAlt, FaFolder } from 'react-icons/fa'
import RecentPostImg01 from '../../img/news1.jpg'
import RecentPostImg02 from '../../img/news2.jpg'
import RecentPostImg03 from '../../img/news3.jpg'
import {
    BlogPostDiv,
    BlogPostBanner,
    BlogPostTitle,
    BlogPostCtn,
    BlogPostFlex,
    BlogPostContent,
    BlogPostSubTitle,
    BlogPostIcons,
    BlogPostCalendar,
    BlogPostIconsP,
    BlogPostUser,
    BlogPostSubject,
    BlogPostText,
    BlogRecentPostCtn,
    RecentPost,
    RecentPostLink,
    RecentPostTitle
} from './blogPost.elements';

export class BlogPost extends React.Component {
    render() {
        return(
            <BlogPostDiv>

                <BlogPostBanner>
                    <BlogPostTitle>{this.props.blogTitle}</BlogPostTitle>
                </BlogPostBanner>

                <BlogPostCtn>

                    <BlogPostFlex>

                        <BlogPostContent>

                            <BlogPostSubTitle>{this.props.subTitle}</BlogPostSubTitle>

                            <BlogPostIcons>

                                <BlogPostCalendar>
                                    <FaRegCalendarAlt className="blogIcon"/>
                                    <BlogPostIconsP>{this.props.date}</BlogPostIconsP>
                                </BlogPostCalendar>

                                <BlogPostUser>
                                    <FaUserAlt className="blogIcon"/>
                                    <BlogPostIconsP>{this.props.user}</BlogPostIconsP>
                                </BlogPostUser>

                                <BlogPostSubject>
                                    <FaFolder className="blogIcon"/>
                                    <BlogPostIconsP>{this.props.subject}</BlogPostIconsP>
                                </BlogPostSubject>

                            </BlogPostIcons>

                                <img src={this.props.blogImg} alt="text" className="blogImg"/>

                            <BlogPostText>{this.props.text01}</BlogPostText>
                            <BlogPostText>{this.props.text02}</BlogPostText>
                            <BlogPostText>{this.props.text03}</BlogPostText>
                            <BlogPostText>{this.props.text04}</BlogPostText>
                            <BlogPostText>{this.props.text05}</BlogPostText>
                            <BlogPostText>{this.props.text06}</BlogPostText>

                        </BlogPostContent>

                        <BlogRecentPostCtn>

                            <RecentPostTitle>Postări Recente</RecentPostTitle>

                            <RecentPost>
                                <img src={RecentPostImg01} alt="" className="recentPostImg"/>
                                <RecentPostLink href="/transportul-auto-in-situatii-de-criza">Transportul Auto</RecentPostLink>
                            </RecentPost>

                            <RecentPost>
                                <img src={RecentPostImg02} alt="" className="recentPostImg"/>
                                <RecentPostLink href="/pozitionare-exacta-a-vehiculelor-autonome">Poziționare exactă a vehiculelor autonome</RecentPostLink>
                            </RecentPost>

                            <RecentPost>
                                <img src={RecentPostImg03} alt="" className="recentPostImg"/>
                                <RecentPostLink href="/cat-consuma-un-camion-5-pasi-simpli-pentru-reducerea-consumului-iarna">Reducerea Consumului</RecentPostLink>
                            </RecentPost>

                        </BlogRecentPostCtn>

                    </BlogPostFlex> 

                </BlogPostCtn>
            </BlogPostDiv>
        );
    }
}

export default BlogPost