import React from 'react';
import ServicesCard from './services.card';
import img01 from '../../img/services1.jpg' 
import img02 from '../../img/services2.jpg' 
import img03 from '../../img/services3.jpg' 
import {
    ServicesBG,
    ServicesContainer,
    ServicesCol1,
    ServicesCol2,
    ServicesCol3,
    ServicesCol4,
    ServicesTitle,
    ServicesParagraph,
    ServicesBtn
} from './services.elements';

export class Services extends React.Component {
    render() {
        return(
            <ServicesBG id="Servicii">
                <ServicesContainer>

                    <ServicesCol1>
                        <ServicesTitle>SERVICII</ServicesTitle>

                        <ServicesParagraph>
                            Dragoserv este o firmă ce se ocupă cu transportul de marfă generală cu ajutorul camioanelor, atat intern cat si extern.
                        </ServicesParagraph>

                        <ServicesBtn><a href="/servicii">MAI MULTE</a></ServicesBtn>
                    </ServicesCol1>

                    <ServicesCol2>
                        <ServicesCard
                            imgSrc={img01}
                            altText="Ingrașăminte"
                            title="Ingrașăminte"
                            text="Azot, fosfat si potasiu, îngrășăminte organice, îngrășăminte lichide etc."
                            link="/servicii"
                        />
                    </ServicesCol2>
                    
                    <ServicesCol3>
                        <ServicesCard
                            imgSrc={img02}
                            altText="Hrană Animala"
                            title="Hrană Animala"
                            text="Borhot de bere, sare, masă verde (lucernă, trifoi, rapiță)."
                            link="/servicii"
                        />
                    </ServicesCol3>

                    <ServicesCol4>
                        <ServicesCard
                            imgSrc={img03}
                            altText="Transport Cereale"
                            title="Transport Cereale"
                            text="Floarea soarelui, grâu, orz, porumb, rapită, soia etc."
                            link="/servicii"
                        />
                    </ServicesCol4>

                </ServicesContainer>
            </ServicesBG>
        )
    }
}

export default Services


