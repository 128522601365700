import React from 'react';
import NumberCard from './number.card';
import {
    NumberBG,
    NumberCtn
} from './number.elements';

export class Number extends React.Component {
    render() {
        return(
            <NumberBG>

                <NumberCtn>

                    <NumberCard
                        title="26"
                        text="Ani de experientă" 
                    />

                    
                    <NumberCard
                        title="20+"
                        text="Camioane" 
                    />

                    
                    <NumberCard
                        title="20+"
                        text="Soferi calificați" 
                    />

                    
                    <NumberCard
                        title="12M"
                        text="Kilometrii pe an" 
                    />

                </NumberCtn>

            </NumberBG>
        )
    }
}

export default Number