import React from 'react';
import {
    NumberContent,
    NumberTitle,
    NumberText
} from './number.elements';

export class NumberCard extends React.Component {
    render() {
        return(

            <NumberContent>
                <NumberTitle>{this.props.title}</NumberTitle>
                <NumberText>{this.props.text}</NumberText>
            </NumberContent>
        )
    }
}

export default NumberCard
