import React from 'react';
import { 
    ServicesCardCtn,
    ServicesCardTitle,
    SericesCardText,
    ServicesCardBtn,
    ServicesCardContent
} from './services.elements';

export class ServicesCard extends React.Component {
    render() {
        return(
            <ServicesCardCtn>

                <img src={this.props.imgSrc} alt={this.props.altText} />

                <ServicesCardContent>
                    
                    <ServicesCardTitle>{this.props.title}</ServicesCardTitle>

                    <SericesCardText>{this.props.text}</SericesCardText>

                    <ServicesCardBtn>
                        <a href={this.props.link}>Mai Multe</a>
                    </ServicesCardBtn>

                </ServicesCardContent>

            </ServicesCardCtn>
        )
    }
}

export default ServicesCard