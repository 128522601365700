import styled from 'styled-components';

export const FooterBG = styled.div`
    background-color: #140B05;
    padding: 20px;
    padding-bottom: 60px;
`

export const FooterCtn = styled.div`
    width: 80%;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (min-width: 1920px) {
        width: 60%;
    }
`

export const FooterCol = styled.div`
    margin-top: 3em;

    hr {
        border: 4px solid #F1A70C;
        border-top: none;
        max-width: 30px;
        margin: 0px 0px 2em 0px;
    }

    @media screen and (min-width: 1024px) {
        width: 180px;
    }
`

export const FooterColTitle = styled.h2`
    color: #fff;

    @media screen and (min-width: 1024px) {
        font-size: 20px;
    }
`

export const FooterParagraph = styled.p`
    color: #B0B0B0;

    @media screen and (min-width: 1024px) {
        font-size: 15px;
    }
`

export const FooterUlFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    margin: 0;
`

export const FooterEmail = styled.p`

    a {
        color: #F1A70B;
        text-decoration: none;
    }
`

export const FooterSocialButtons = styled.div`

    display: flex;
    justify-content: space-between;
    max-width: 180px;
    margin-top: 1.5em;

    .footerIcon {
        color: #fff;
        font-size: 20px;
    }

    .facebookIcon:hover {
        color: #395693;
        cursor: pointer;
    }

    .twitterIcon:hover {
        color: #00ACEE;
        cursor: pointer;
    }

    .googleIcon:hover {
        color: #E06354;
        cursor: pointer;
    }

    .linkedinIcon:hover {
        color: #0077B5;
        cursor: pointer;
    }

    .instagramIcon:hover {
        color: #DD2A7B;
        cursor: pointer;
    }
`

export const FooterLinksCtn = styled.div``

export const FooterLink = styled.p`

    a {
        cursor: pointer;
        color: #F1A70B;
        text-decoration: none;
    }
`

