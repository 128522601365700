import styled from 'styled-components';
import bgImg from '../../img/contact.jpg';

export const SolutionBG = styled.div`
    background-image: url(${bgImg});
    background-attachment: fixed;
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    padding-bottom: 60px;
    min-height: 600px;

    @media screen and (min-width: 1024px) {
        min-height: 400px;
    }
`

export const SolutionCtn = styled.div`
    width: 80%;
    margin: 0 auto;

    @media screen and (min-width: 1920px) {
        width: 60%;
    }
`

export const SolutionTitle = styled.h1`
    color: #fff;
`

export const SolutionText = styled.p`
    color: #fff;
    font-size: 20px;
    margin-top: 3em;

    @media screen and (min-width: 1280px) {
        width: 700px;
    }
`

export const SolutionBtn = styled.button`
    cursor: pointer;
    border: none;
    border-radius: 3px;
    background-color: #F1A70C;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: bold;
    margin-top: 2em;
    width: 140px;
    height: 50px;

    a {
        color: #fff;
        text-decoration: none;
    }

    &:hover {
        background-color: #ccc;
        transition: 0.3s ease-in;
    }
`
