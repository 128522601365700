import styled from 'styled-components';
import bgImg from '../../img/number.png';

export const NumberBG = styled.div`
    background-image: url(${bgImg});
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
`

export const NumberCtn = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    text-align: center;

    @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (min-width: 1920px) {
        width: 60%;
    }
`

export const NumberContent = styled.div`
    margin: 5.2em 0em;
`

export const NumberTitle = styled.h2`
    color: #F1A70C;
    font-size: 32px;
`

export const NumberText = styled.p`
    color: #fff;
    font-weight: bold;
`

