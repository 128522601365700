import React from 'react'
import ClientsCard from './clients.card'
import {
    ClientsBG,
    ClientsTitle,
    ClientsCtn,
    ClientsCol
} from './clients.elements';

export class Clients extends React.Component {

    render() {
        return (
            <ClientsBG id="Clienti">
                
                <ClientsTitle>Ce Spun Clienții Nostri</ClientsTitle>

                <ClientsCtn>

                    <ClientsCol>

                        <ClientsCard
                            text="Am fost foarte mulțumit de serviciile oferite de Dragoserv. Cei de la birou s-au comportat exemplar! Mi-au răspus la telefon rapid, si au răspuns la toate întrebarile." 
                            name="– Radu T."
                            role="Client, Dragoserv"
                        />

                    </ClientsCol>

                    <ClientsCol>

                        <ClientsCard
                            text="Am lucrat cu Dragoserv pentru mult timp. Au oferit servicii de calitate si asistență. Cei de la Dragoserv fac comunicarea sa fie foarte usoară, la mail sau telefon. Recomand cu mare încredere." 
                            name="– Mircea R."
                            role="Client, Dragoserv"
                        />

                    </ClientsCol>

                </ClientsCtn>

            </ClientsBG>
        )
    }
}

export default Clients
