import React from 'react';
import BlogPost from '../components/blogPost/index';
import blogImg from '../img/news1.jpg';

export class BlogPost02 extends React.Component {
    render() {
        return(
            <div>
                <BlogPost 
                    blogTitle="Transportul Auto"
                    subTitle="În această perioadă prin care trecem împreună, e important să rămânem responsabili"
                    date="12.02.2021"
                    user="Andrei"
                    subject="Transportul Auto"
                    blogImg={blogImg}
                    text01="În situațiile de criză se iau măsuri suplimentare, iar în contextul epidemiei de COVID-19, UNTRR - Uniunea Națională a transportatorilor rutieri din România le-a menționat și pe site."
                    text02="Este vorba de măsuri pentru a preveni răspândirea COVID-19, fiind implementate mai multe măsuri și urmărirea continuă a evoluției evenimentelor pentru a aplica noi soluții pentru siguranța șoferilor de tir și camioane, precum și a celorlalte categorii de populație. Truckmagia.ro  vă prezintă noutățile la zi privind aceste măsuri, pentru a contribui cu cât putem la informarea Dvs."
                    text03="În această perioadă prin care trecem împreună, deși nu e ușor pentru nimeni, e important să rămânem responsabili, echilibrați și să gândim pozitiv, precum ne spune UNTRR."
                    text04="Mai.gov.ro a publicat o informare a Grupului de Comunicare Strategică – Guvernul României a lansat platforma cemafac.ro, un ghid cu recomandări pentru populație în funcție de scenariile posibile prin care au fost întocmite derogări de la REGULAMENTUL (CE) NR. 561/2006."
                    text05="Despre acest normativ unde sunt stipulate condițiile pentru conducătorii auto profesioniști în unele state UE și Non-UE vom detalia cât de curând."
                    text06="Sursa: truckmagia.ro"
                />
            </div>
        );
    }
}

export default BlogPost02