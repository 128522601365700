import styled from 'styled-components';

export const FeaturesCtn = styled.div`
    width: 80%;
    margin: 0.5em auto;
    padding: 20px 0px;

    @media screen and (min-width: 1024px) {
        padding-bottom: 4em;
    }

    @media screen and (min-width: 1920px) {
        width: 60%;
    }
`

export const FeaturesTitleCtn = styled.div`

    hr {
        border: 2px solid #989898;
        border-top: none;
        max-width: 100px;
        margin: 0px;
    }
`

export const FeaturesTitle = styled.h3`
    font-size: 18px;
    font-weight: 400;
    position: relative;
    top: 25px;
`

export const FeaturesSubTitle = styled.h2`
    font-size: 25px;
    font-weight: 800;

    @media screen and (min-width: 768px) {
        font-size: 30px;
    }
`

export const FeaturesIconCtn = styled.div`
    .icon {
        color: #F6C661;
        font-size: 40px;

        @media screen and (min-width: 768px) {
            font-size: 60px;
        }

        @media screen and (min-width: 1024px) {
            font-size: 60px;
        }
    }
`

export const FeaturesIconFlex = styled.div`
    display: flex;
    align-items: center;
    margin-top: 2em;

    @media screen and (min-width: 1024px) {
        width: 400px;
    }

    @media screen and (min-width: 1600px) {
        width: 450px;
    }
`

export const FeaturesIconFlexCtn = styled.div`
    padding: 0px 20px 0px 0px;
`

export const FeaturesIconFlexText = styled.div``

export const FeaturesIconTitle = styled.h3`
    font-size: 15px;
    font-weight: 500;

    &:hover {
        color: #F1A70C;
        transition: 0.3s ease-in;
        cursor: pointer;
    }

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }

    @media screen and (min-width: 1024px) {
        font-size: 17px;
    }

    @media screen and (min-width: 1600px) {
        font-size: 20px;
    }
`

export const FeaturesIconParagraph = styled.p`
    font-size: 13px;
    color: #989898;

    @media screen and (min-width: 768px) {
        font-size: 18px;
    }

    @media screen and (min-width: 1024px) {
        font-size: 15px;
    }

    @media screen and (min-width: 1600px) {
        font-size: 17px;
    }
`

export const FeaturesFlex = styled.div`
    @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const FeaturesImgCtn = styled.div`
    img {
        width: 100%;
    }

    @media screen and (min-width: 1600px) {
        width: 50%;
    }
`
