import React from 'react';
import { Link } from 'react-scroll';
import {
    BannerBackgroundImage,
    BannerContainer,
    BannerTitle,
    BannerText,
    BannerBtnCtn,
    BannerBtnActive,
    BannerBtn
} from './banner.elements';

export class Banner extends React.Component {
    render() {
        return(
            <BannerBackgroundImage>

                <BannerContainer>

                    <BannerTitle>TRANSPORT DE MARFĂ CU CAMIOANE</BannerTitle>
                    <BannerText>
                        Societatea noastră a fost înființată in anul 1994 , ca o afacere de familie.
                        Datorită profesionalismului si seriozității de care am dat dovadă am reușit să ne formăm clienți fideli cu care lucrăm si astăzi.
                    </BannerText>

                    <BannerBtnCtn>

                        <BannerBtnActive>

                            <Link
                                activeClass="active"
                                to="Contact"
                                spy={true}
                                smooth={true}
                                offset={-200}
                                duration={500}
                            >
                                <a>CONTACT</a>
                            </Link>

                        </BannerBtnActive>

                        <BannerBtn>

                            <Link
                                activeClass="active"
                                to="DespreNoi"
                                spy={true}
                                smooth={true}
                                offset={-200}
                                duration={500}
                            >
                                <a href="">DESPRE NOI</a>
                            </Link>

                        </BannerBtn>

                    </BannerBtnCtn>

                </BannerContainer>

            </BannerBackgroundImage>
        )
    }
}

export default Banner