import React from 'react';
import BlogPost from '../components/blogPost/index';
import blogImg from '../img/news3.jpg';

export class BlogPost03 extends React.Component {
    render() {
        return(
            <div>
                <BlogPost 
                    blogTitle="Reducerea Consumului"
                    subTitle="Cât consumă un camion, 5 pași simpli pentru reducerea consumului iarna"
                    date="08.02.2021"
                    user="Andrei"
                    subject="Reducerea Consumului"
                    blogImg={blogImg}
                    text01="Daf, Volvo, Man, Renault, Iveco, Scania, Mercedes …..si lista poate continua. O gama variata de camioane circula acum pe sosele   si fiecare  sofer de tir  s-a intrebat la un moment dat cat consuma camionul lui."
                    text02="Sunt multe variabile  de luat in calcul pentru a face o etimare a celui mai bun consum pentru un camion, cum ar fi: viteza de deplasare, starea carosabilului, starea cauciurilor, experienta celui care conduce ,relieful ( munte  sau vale) etc."
                    text03="Urmand acesti  5 pasi fara  o investitie majora impactul asupra consumului de carburant se poate  rediuce  intre 4 si 15%."
                    text04="1.Verificati inainte de fiecare cursa uleiul, lichidul de frina  si cel de racire
 
                    2.Verificati daca cauciucurile au presiunea corespunzatoare ( un cauciuc care are mai putina presiune decat media permisa va ingreuna miscarea camionului cee ace va duce implicit la cresterea consumului)
                     
                    3.Faceti un inventor al lucrurilor personale din cabina camionului.Recomandam pentru confort  utizarea de covorase  cabina din piele ecologica care sunt usoare, se curata rapid  si  au si un aspect placut putand fii asortate coloristic .
                     
                    4.Utillzati cutii de depozitare pentru a tine inventarul obiectelor necesare ,indepartand orice articol care nu il utiilizati pe perioada iernii .
                     
                    5.Conduceti cu o viteza constanta pentru a evita supraturarea sau subturarea motorului."
                    text05=" Din 2007  CE a impus companiilor de transport sa reduca emisiile de CO2 prin proiectarea unor motoare care sa reduca noxele. Asta inseamna ca indifferent de modelul de camion, cele care au o vechime mai mare de 10 ani vor avea intotdeauna un consum mai mare , tendinta industriei producatoare de camioane find de a se focusa pe reducerea emisiilor si nu neaparat pe reducerea consumului de combustibil. Desi ar fi de preferat ca din punct de vedere economic consumul sa fie cat mai mic impactul asupra incalzirii globale este devastataoare.Si pandemia ne-a demonstrat ca atunci cand planeta respira si viata incetineste ritmul ei nebun natura isi revine. Deci intrebarea care camion consuma mai mult schimbata cu  : ce camion produce mai putine noxe?"
                    text06="Sursa: truckmagia.ro"
                />
            </div>
        );
    }
}

export default BlogPost03