import styled from 'styled-components';

export const MapsCtn = styled.div`
    position: relative;
    top: 10px;

    iframe {
        width: 100%;
        height: 300px;
        border: none;
    }
`