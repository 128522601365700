import React from 'react';
import NewsCard from '../components/news/news.card';
import NewsImg01 from '../img/news1.jpg';
import NewsImg02 from '../img/news2.jpg';
import NewsImg03 from '../img/news3.jpg';
import {
    BlogCtn,
    BlogTitle,
    BlogHR,
    BlogArticleFlex
} from './page.elements';

export class Blog extends React.Component {
    render() {
        return(
            <BlogCtn>
                <BlogTitle>BLOG</BlogTitle>
                <BlogHR />

                <BlogArticleFlex>

                    <NewsCard 
                        img={NewsImg01}
                        altText="News 01"
                        date="12.02.2021"
                        title="Transportul Auto"
                        text="În situațiile de criză se iau măsuri suplimentare, iar în contextul epidemiei de COVID-19..."
                        link="/transportul-auto-in-situatii-de-criza"
                    />

                    <NewsCard 
                        img={NewsImg02}
                        altText="News 01"
                        date="18.02.2021"
                        title="Poziționare exactă a vehiculelor"
                        text="Galileo, sistemul global de navigaţie prin satelit, în combinaţie cu alte tehnologii de..."
                        link="/pozitionare-exacta-a-vehiculelor-autonome"
                    />

                    <NewsCard 
                        img={NewsImg03}
                        altText="News 01"
                        date="08.02.2021"
                        title="Reducerea Consumului"
                        text="Daf, Volvo, Man, Renault, Iveco, Scania, Mercedes …..si lista poate continua. O gama variata de camioane..."
                        link="/cat-consuma-un-camion-5-pasi-simpli-pentru-reducerea-consumului-iarna"
                    />


                </BlogArticleFlex>
            </BlogCtn>
        )
    }
}

export default Blog