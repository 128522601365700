import styled from 'styled-components';

export const NewsCtn = styled.div`
    width: 80%;
    margin: 2em auto;
    padding: 20px;

    @media screen and (min-width: 1920px) {
        width: 60%;
        padding-bottom: 4em;
    }
`

export const NewsTitle = styled.h1`
    text-align: center;
`

export const NewsCardFlex = styled.div`

    @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const NewsCardCtn = styled.div`
    background-color: #F6F5F3;
    border-radius: 4px;
    margin-top: 4em;

    img {
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        max-width: 260px;
        min-height: 460px;
    }

    @media screen and (min-width: 1280px) {
        max-width: 320px;
        min-height: 480px;
    }
`

export const NewsCardDate = styled.h4`
    color: #AAA2B4;
    font-weight: lighter;
    font-size: 15px;
`

export const NewsCardTitle = styled.h3`
    font-weight: 500;
`

export const NewsCardText = styled.p``

export const NewsCardButton = styled.button`
    border: none;
    border-radius: 3px;
    background-color: #F1A70C;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: bold;
    width: 140px;
    height: 40px;

    a {
        color: #fff;
        text-decoration: none;
    }
`

export const NewsCardContent = styled.div`
    padding: 20px;
    text-align: center;
`
