import React from 'react';
import { MapsCtn } from './maps.elements';

export class Maps extends React.Component {
    render() {
        return(
            <MapsCtn>
                <iframe
                    title="Dragoserv Locatie"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d363598.29340250767!2d25.917877574218124!3d44.6046419113981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xae77cb85457067c7!2sDragoserv%20SRL!5e0!3m2!1sen!2sro!4v1611176991317!5m2!1sen!2sro">    
                </iframe>
            </MapsCtn>
        )
    }
}

export default Maps