import styled from 'styled-components';

export const ServicesDiv = styled.div`
    /* cursor: pointer; */
    width: 65%;
    margin: 0 auto;

    * {
        margin: 0px;
        padding: 0px;
    } 
`
export const ServicesTitle = styled.h1`
    margin-top: 5em;
    margin-bottom: 3em;
    text-align: center;
`

export const ServicesCtn = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
`

export const ServicesCardHover = styled.div`
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #140B05;
    position: absolute;
    padding: 0px 20px;
    z-index: 100;
    width: 380px;
    height: 100%;
`

export const ServicesCardCtn = styled.div`
    position: relative;
    width: 420px;
    margin-bottom: 4em;

    img {
        width: 100%;
    }
`

export const ServicesCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
`

export const ServicesCardTitle = styled.h2`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 18px;
    background-color: #140B05;
    color: #fff;
    text-align: center;
    width: 240px;
    height: 40px;

    @media screen and (min-width: 280px) and (max-width: 768px){
        font-size: 15px;
        width: 140px;
        height: 30px;
    } 
`

export const SerivicesCardText = styled.p`
    color: #fff;
    font-size: 40px;
`

export const ServicesHR = styled.hr`
    border: 4px solid #F1A70C;
    border-top: none;
    max-width: 30px;
    margin: 0 auto;
    position: relative;
    bottom: 90px;
`

export const ServicesText = styled.p`
    text-align: center;
    font-size: 18px;
    width: 60%;
    margin: 0 auto;
    position: relative;
    bottom: 50px;

    @media screen and (min-width: 320px) and (max-width: 768px){
        font-size: 15px;
        width: 100%;
    } 
`

export const BlogCtn = styled.div`
    width: 60%;
    margin: 0 auto;
`

export const BlogTitle = styled.h1`
    margin-top: 5em;
    text-align: center;
`

export const BlogHR = styled.hr`
    border: 4px solid #F1A70C;
    border-top: none;
    max-width: 30px;
    margin: 0 auto;
    position: relative;
    bottom: 10px;
`

export const BlogArticleFlex = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 8em;
`