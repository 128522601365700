import React from 'react';
import BlogPost from '../components/blogPost/index';
import blogImg from '../img/news2.jpg';

export class BlogPost01 extends React.Component {
    render() {
        return(
            <div>
                <BlogPost 
                    blogTitle="Poziționare exactă a vehiculelor autonome"
                    subTitle="Vehiculele autonome şi sistemele avansate de asistenţă a şoferilor"
                    date="18.02.2021"
                    user="Andrei"
                    subject="Vehicule Autonome"
                    blogImg={blogImg}
                    text01="Galileo, sistemul global de navigaţie prin satelit, în combinaţie cu alte tehnologii de poziţionare şi cu senzorii, este răspunsul la acest puzzle de poziţionare. Soluţia inovatoare a fost creată prin proiectul PRoPART ­– Precise and Robust Positioning for Automated Road Transports – dezvoltat de Scania împreună cu şase parteneri  – şi ar putea fi un element cheie pentru transporturile autonome din viitor."
                    text02="Soluţia a fost recent demonstrată într-o situaţie pe o autostradă recreată în zona de testare AstaZero din Suedia, cu un camion automat conectat şi două vehicule neconectate, conduse de şoferi."
                    text03="Ca parte a testului, un camion autonom Scania a executat o schimbare sigură şi eficientă a benzii de drum. Manevra a fost gestionată de noul sistem, ce are la bază poziţionarea la centimetru, combinată cu datele oferite de senzorii de proximitate."
                    text04="Proiectul a demonstrat că este posibilă stabilirea poziţiei cu o acurateţe de zece centimetri. Camionul a putut executa manevra datorită poziţionării precise şi a unei reprezentări exacte a întregului mediu înconjurător. Acest lucru a fost realizat fuzionând date obţinute de camera de luat vederi a camionului şi de radarele frontale şi laterale, combinate cu radarele montate pe unităţile de la marginea şoselei."
                    text05="„În afară de poziţionare, am adăugat şi comunicare între infrastructură şi vehicul”, spune Coordonatorul de Proiect Stefan Nord de la RISE, institutul suedez de cercetare."
                    text06="Sursa: camioane.scania.ro"
                />
            </div>
        );
    }
}

export default BlogPost01