import React from 'react';
import {
    NewsCardCtn,
    NewsCardDate,
    NewsCardTitle,
    NewsCardText,
    NewsCardButton,
    NewsCardContent
} from './news.elements';

export class NewsCard extends React.Component {
    render() {
        return(
            <NewsCardCtn>

                <img src={this.props.img} alt={this.props.altText}/>

                <NewsCardContent>

                    <NewsCardDate>{this.props.date}</NewsCardDate>
                    <NewsCardTitle>{this.props.title}</NewsCardTitle>
                    <NewsCardText>{this.props.text}</NewsCardText>
                    <NewsCardButton><a href={this.props.link}>CITEȘTE</a></NewsCardButton>

                </NewsCardContent>

            </NewsCardCtn>
        )
    }
}

export default NewsCard