import React, { useState } from 'react';
import { Link } from 'react-scroll';
import {
    Nav,
    Hamburger,
    Logo,
    Menu,
    MenuLink,
    NavCtn
} from './navbar.elements';

export function NavStickyScroll() {
    const nav = document.querySelector('.navCtn');
    const menuHome = document.querySelector('#MenuHome')
    const menuPages = document.querySelector('#MenuPages')
    if(window.scrollY > 0 && window.location.pathname !== '/servicii') {
        nav.style.backgroundColor = '#140B05';
        nav.style.transition = '0.3s ease';
    } else if(window.scrollY === 0) {
        if(
            window.location.pathname === '/servicii' ||
            window.location.pathname === '/blog' ||
            window.location.pathname === '/pozitionare-exacta-a-vehiculelor-autonome' ||
            window.location.pathname === '/transportul-auto-in-situatii-de-criza' ||
            window.location.pathname === '/cat-consuma-un-camion-5-pasi-simpli-pentru-reducerea-consumului-iarna'
            ) {
            nav.style.backgroundColor = '#140B05';
            nav.style.position = 'fixed';
            nav.style.top = '0px';
            menuHome.style.display = 'none';
            menuPages.style.display = 'flex';
            menuPages.classList.add('menuPagesFlex');
        } else {
            nav.style.backgroundColor = 'transparent';
        }
    }
}

window.addEventListener('load', NavStickyScroll);

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <NavCtn className="navCtn">
        <Nav id="Acasa">
            <Logo href=""><span>Dragoserv</span>
            </Logo>
            <Hamburger onClick={() => setIsOpen(!isOpen)}>
                <span />
                <span />
                <span />
            </Hamburger>
            <Menu isOpen={isOpen} id="MenuHome">

                <Link
                    activeClass="active"
                    to="DespreNoi"
                    spy={true}
                    smooth={true}
                    offset={-200}
                    duration={500}
                >
                    <MenuLink>Despre Noi</MenuLink>
                </Link>

                <Link 
                    activeClass="active"
                    to="Servicii"
                    spy={true}
                    smooth={true}
                    offset={-60}
                    duration={500}
                >
                    <MenuLink>Servicii</MenuLink>
                </Link>

                <Link 
                    activeClass="active"
                    to="Caracteristici"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <MenuLink>Caracteristici</MenuLink>
                </Link>

                <Link 
                    activeClass="active"
                    to="Stiri"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <MenuLink>Știri</MenuLink>
                </Link>

            </Menu>

            <Menu id="MenuPages" isOpen={isOpen}>
                <MenuLink href="/">Acasă</MenuLink>
                <MenuLink href="/servicii">Servicii</MenuLink>
                <MenuLink href="/blog">Știri</MenuLink>
            </Menu>

        </Nav>
        </NavCtn>   
    )
}

export default Navbar

