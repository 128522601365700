import styled from 'styled-components';

export const NavCtn = styled.div`
    position: fixed;
    width: 100vw;
    height: 60px;
    z-index: 100;

    @media screen and (min-width: 1000px) {
        padding-bottom: 10px;
    }
`

export const Nav = styled.div`
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* background: #140B05; */
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 76%;

    @media screen and (min-width: 1920px) {
        width: 60%;
    }

    #MenuPages {
        display: none;
    }
`;

export const Hamburger = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;

    span {
        height: 4px;
        width: 25px;
        background: white;
        margin-bottom: 4px;
        border-radius: 5px;
    }

    @media (max-width: 768px) {
        display: flex;
    }
`;

export const Logo = styled.a`
    padding: 1rem 0;
    color: #fff;
    text-decoration: none;

    span {
        font-weight: 300;
        font-size: 1.3rem;

        @media screen and (min-width: 768px) {
            font-size: 1.5rem;
        }
    }
`;

export const Menu = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;

    @media screen and (max-width: 768px) {
        background: #140B05;
    }

    @media (max-width: 768px) {
        overflow: hidden;
        flex-direction: column;
        width: 100%;
        height: 400px;
        max-height: ${({isOpen}) => (isOpen ? "1000px" : "0px")};
        transition: max-height 0.3s ease-in;
    }
`;

export const MenuLink = styled.a`
    padding: 1rem 2rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: #fff;
    transition: all 0.3s ease-in;
    font-size: 0.9rem;
`;

export const ScrollTopDivCtn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
`

export const ScrollTopDiv = styled.div`
    display: none;
    position: fixed;
    bottom: 50px;
    right: 50px;
    background-color: #F1A70C;
    border-radius: 200px;
    width: 40px;
    height: 40px;

    &:hover {
        background-color: #ccc;
        transition: 0.3s ease-in;
    }

    .arrowUp {
        color: #fff;
        font-size: 30px;
        cursor: pointer;
        text-align: center;
    }
`