import React from 'react';
import { Link } from 'react-scroll';
import { RiFacebookFill, RiTwitterFill } from 'react-icons/ri'
import { FaGooglePlusG, FaLinkedinIn, FaInstagram } from 'react-icons/fa'
import {
    FooterBG,
    FooterCtn,
    FooterCol,
    FooterColTitle,
    FooterParagraph,
    FooterUlFlex,
    FooterEmail,
    FooterSocialButtons,
    FooterLinksCtn,
    FooterLink
} from './footer.elements';
 
export class Footer extends React.Component {
    render() {
        return(
            <FooterBG>

                <FooterCtn>

                    <FooterCol>

                        <FooterColTitle>Despre noi</FooterColTitle>
                        <hr/>
                        <FooterParagraph>
                            Dragoserv este o firmă ce se ocupă cu transportul
                            de marfă generală cu ajutorul camioanelor, atat intern cat si extern.
                        </FooterParagraph>

                    </FooterCol>

                    <FooterCol>

                        <FooterColTitle>Informații</FooterColTitle>
                        <hr/>

                        <FooterUlFlex>

                            <FooterLinksCtn>

                                <Link 
                                    activeClass="active"
                                    to="Acasa"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={500}
                                >
                                    <FooterLink><a>Acasă</a></FooterLink>
                                </Link>

                                <Link 
                                    activeClass="active"
                                    to="Caracteristici"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={500}
                                >
                                    <FooterLink><a>Caracteristici</a></FooterLink>
                                </Link>

                                <Link 
                                    activeClass="active"
                                    to="Servicii"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={500}
                                >
                                    <FooterLink><a>Servicii</a></FooterLink>
                                </Link>

                            </FooterLinksCtn>

                            <FooterLinksCtn>

                                <Link 
                                    activeClass="active"
                                    to="Stiri"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={500}
                                >
                                    <FooterLink><a>Știri</a></FooterLink>
                                </Link>

                                <Link 
                                    activeClass="active"
                                    to="Clienti"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={500}
                                >
                                    <FooterLink><a>Clienți</a></FooterLink>
                                </Link>

                                <Link 
                                    activeClass="active"
                                    to="Contact"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={500}
                                >
                                    <FooterLink><a>Contact</a></FooterLink>
                                </Link>

                            </FooterLinksCtn>

                        </FooterUlFlex>

                    </FooterCol>

                    <FooterCol id="Contact">

                        <FooterColTitle>Contact</FooterColTitle>
                        <hr/>
                        <FooterParagraph>Sună la 0722.363.597</FooterParagraph>
                        <FooterParagraph>Strada Eroilor, Comuna Moara Vlasiei, Ilfov</FooterParagraph>
                        <FooterEmail><a href="mailto:office@dragoserv.ro">office@dragoserv.ro</a></FooterEmail>

                    </FooterCol>

                    <FooterCol>

                        <FooterColTitle>Social</FooterColTitle>
                        <hr/>
                        <FooterParagraph>Vizitează paginile noastre de socializare.</FooterParagraph>

                        <FooterSocialButtons>

                            <a href="https://www.facebook.com/Drago-Serv-871514772978079" target="_blank" ><RiFacebookFill className="facebookIcon footerIcon"/></a>
                            <RiTwitterFill className="twitterIcon footerIcon"/>
                            <FaGooglePlusG className="googleIcon footerIcon"/>
                            <FaLinkedinIn className="linkedinIcon footerIcon"/>
                            <FaInstagram className="instagramIcon footerIcon"/>

                        </FooterSocialButtons>

                    </FooterCol>

                </FooterCtn>

            </FooterBG>
        )
    }
}

export default Footer